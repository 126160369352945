.plot-container .modebar {
	position: fixed !important; /* Fix the modebar to the viewport */
	padding-left: 10px !important;
	right: 20px; /* Position it 20px from the right */
	z-index: 2000; /* Ensure it appears above other content */
	background-color: white;
  }
  
  .static-legend {
	position: fixed;
	bottom: 10px;
	right: 5px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 5px;
	z-index: 2000;
	width: 100px;
	font-size:9px;
  }
  
  .legend-item {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
  }
  
  .legend-color {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 4px;
  }
  